<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="1250"
        persistent
      >
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Nuevo Crédito</v-toolbar>
            <v-container>
              <v-progress-linear
                v-if="loading"
                :loading="loading"
                indeterminate
              ></v-progress-linear>
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" md="6">
                    <company-field
                      v-model="companyId"
                      @value="(val) => (companyId = val)"
                    ></company-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <account-field
                      v-model="body.account_id"
                      :companyId="companyId"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <reason-movement-field
                      v-model="reason"
                      kind="CRE"
                      :rules="onlyRequired"
                      :filterReason="true"
                    ></reason-movement-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <date-field
                      label="Fecha"
                      v-model="body.movement_date"
                      :error-messages="movementDateError"
                      @clear="movementDateError = []"
                      :rules="onlyRequired"
                    ></date-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="No. Boleta"
                      :rules="textRules"
                      v-model="body.code"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Valor"
                      v-model="body.cre"
                      :rules="valueRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <tt-field v-model="body.tt_id" :credeb="'CRE'"></tt-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Descripción"
                      v-model="body.description"
                      counter="200"
                      :rules="textRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" @click="resetForm()"> Cancelar </v-btn>
                  <v-btn
                    color="success"
                    @click="create()"
                    :disabled="!valid"
                    :loading="loading"
                  >
                    Registrar
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>


<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  components: {
    AccountField: () => import("@/components/fields/AccountField"),
    CompanyField: () => import("@/components/fields/CompanyField"),
    DateField: () => import("@/components/fields/DateField"),
    ReasonMovementField: () =>
      import("@/components/fields/ReasonMovementField"),
    TtField: () => import("@/components/fields/TTField"),
  },
  props: {
    dialog: { type: Boolean, required: true },
  },
  data() {
    return {
      reason: {},
      reset: false,
      companyId: 0,
      valid: false,
      showDocumentField: false,
      loading: false,
      body: {
        account_id: 0,
        tt_id: 0,
        reason_id: 0,
        code: "",
        check_code: null,
        description: "",
        movement_date: "",
        cre: "",
        document: " ",
        credeb: "cre",
        reason_detail: false,
      },
      //form rules
      textRules: [rules.required, rules.minLength(3)],
      onlyRequired: [rules.required],
      valueRules: [rules.required, rules.minValue(0.01)],
      // Errors messages
      movementDateError: [],
    };
  },
  watch: {
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
    },
    reason(newValue) {
      if (typeof newValue === "object") {
        if (Object.keys(newValue).length > 0) {
          this.body.reason_id = newValue.id;

          this.showDocumentField = newValue.document_required_name;
        }
      }
    },
  },
  methods: {
    create() {
      this.loading = true;
      if (this.body.document == null) {
        this.body.document = " ";
      }
      requests
        .post("api/accounts/movements-in/", this.body)
        .then((response) => {
          if (response.status == 201) {
            this.resetForm();
            this.$toasted.global.info({
              message: "Registro creado",
            });
          } else if (response.status == 400) {
            if (response.data.non_field_errors) {
              this.$toasted.global.error({
                message:
                  "Ya existe una operación registrada con este número de boleta",
              });
            }
            if (response.data.code) {
              this.$toasted.global.error({
                message: response.data.code,
              });
            }
            if (response.data.detail) {
              this.$toasted.global.error({
                message: response.data.detail,
              });
            }
            if (response.data.movement_date != undefined) {
              this.movementDateError = response.data.movement_date;
            }
            this.movementDateError = response.data.movement_date;
            console.log(response);
          }
          this.loading = false;
        });
    },
    resetForm() {
      this.totalReason = 0;
      this.body = {
        account_id: 0,
        tt_id: 0,
        reason_id: 0,
        code: "",
        check_code: null,
        description: "",
        movement_date: "",
        cre: "",
        document: " ",
        credeb: "cre",
        reason_detail: false,
      };
      this.valid = false;
      this.showDocumentField = false;
      this.reason = {};
      this.$refs.form.reset();
      this.$emit("close");
    },
  },
};
</script>
